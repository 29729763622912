<template>
  <div ref="Inventory" :style="{ width: '100%', height: '300px' }"></div>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      //定义变量来装图表对象
      chart: null,
    };
  },
  mounted() {
    this.getEchartData();
    //图表自适应大小
    window.addEventListener("resize", this.chart.resize);
  },
  methods: {
    getEchartData() {
      const eChart = this.$refs.Inventory;
      this.chart = echarts.init(eChart);
      const option = {
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

            params.forEach((item) => {
              var aaa = this.kmb(item.value);
              result += `<span style="">${item.seriesName} :${aaa}%</span></br>`;
            });
            return result;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        legend: {
          textStyle: {
            color: "#ffff",
          },
        },
        xAxis: {
          data: [],
          axisLabel: {
            textStyle: {
              color: "#FFF",
            },
          },
        },

        yAxis: {
          type: "value",
          axisLabel: {
            // margin: 2, //图表显示y轴数据调试修改距离设置外边距
            textStyle: {
              color: "#FFF",
            },
          },
        },
        // grid: {
        //   left: 90,
        // }, //图表显示y轴数据调试修改距离：在axisLabel属性加入margin:2；添加属性grid，具体值可调试。
        graphic: [
          {
            type: "image",
            id: "logo",
            right: "center",
            bottom: "center",
            z: 0,
            bounding: "all",
            style: {
              image:
                "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
              width: 320,
              height: 54,
            },
          },
        ],
        series: [
          {
            name: "持仓",
            data: [],
            type: "bar",
          },
        ],
      };
      // 设置配置项
      this.chart.setOption(option);
    },
  },
};
</script>
<style lang="scss">
</style>
