<template>
  <div ref="CustomizedPie" :style="{ width: '100%', height: '300px' }"></div>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {},
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.chart.resize);
  },
  methods: {
    getEchartData() {
      const eChart = this.$refs.CustomizedPie;
      this.chart = echarts.init(eChart);
      const option = {
        // backgroundColor: "#2c343c",
        title: {
          text: "",
          left: "center",
          top: 20,
          textStyle: {
            color: "#ccc",
          },
        },
        tooltip: {
          trigger: "item",
          borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: [
              { },
              { },
            ],
            // roseType: "radius",
            label: {
              color: "#FFFF",
            },
            labelLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.3)",
              },
              smooth: 0.2,
              length: 10,
              length2: 20,
            },
            itemStyle: {
              color: "#c23531",
              shadowBlur: 200,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              normal: {
                //饼状图上的文本显示
                label: {
                  show: true,
                  position: "outside",//标签的位置'outside'饼图扇区外侧'inside'饼图扇区内部。
                  formatter: "{d}%", //"{c}({d}%)"数值和百分比
                  textStyle: {
                    fontSize: 12,
                    fontWeight: "bolder",
                  },
                },
                labelLine: {
                  show: true, //显示饼状图上的文本时，指示线不显示，在第一个data时显示指示线
                },
              },
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>
<style lang="scss">
</style>