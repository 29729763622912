import request from "@/utils/request"
//获取bito基金控股信息
export function getBitoFundData() {
    return request({
        method: 'get',
        url: '/bitoHoldings',
        params:{},
    })
}
//获取bito价格信息
export function getBitoPriceData() {
    return request({
        method: 'get',
        url: '/bitoPrice',
        params:{},
    })
}
//获取Btf基金控股信息
export function getBtfFundData() {
    return request({
        method: 'get',
        url: '/btfHoldings',
        params:{},
    })
}
//获取Proshare基金控股信息
export function getProshareFundData() {
    return request({
        method: 'get',
        url: '/purposeHoldings',
        params:{},
    })
}

//获取btf价格信息
export function getBtfPriceData() {
    return request({
        method: 'get',
        url: '/btfPrice',
        params:{},
    })
}
//获取AllBrowse总览所有规模信息
export function getAllBrowseScaleData() {
    return request({
        method: 'get',
        url: '/etfPositions',
        params:{},
    })
}

//获取AllBrowse总览所有持仓信息
export function getAllBrowsePositionData() {
    return request({
        method: 'get',
        url: '/etfHoldings',
        params:{},
    })
}
//获取AllBrowse总览所有持仓占总市比例信息
export function getAllBrowseRateData() {
    return request({
        method: 'get',
        url: '/etfRate',
        params:{},
    })
}