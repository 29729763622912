<template>
  <section>
    <b-row>
      <b-col lg="12" md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>BTF 比特币期货 ETF</b-card-title>
          </b-card-header>
        </b-card>
      </b-col>

      <b-col>
        <b-card no-body class="prosharesPrice">
          <b-card-header>
            <b-card-title> 价格信息 {{ time }} </b-card-title>
          </b-card-header>
          <b-card-body>
            <table class="proPrice">
              <tr>
                <td>
                  <div class="name">资产净值</div>
                  <div class="price">$ {{ priceData.nav }}</div>
                </td>
                <td>
                  <div class="name">资产净值变化</div>
                  <div class="price">$ {{ priceData.nav_change_dollars }}</div>
                </td>
                <td>
                  <div class="name">市场价值</div>
                  <div class="price">$ {{ priceData.market_price }}</div>
                </td>
                <td>
                  <div class="name">市场涨跌幅</div>
                  <div class="price">
                    $ {{ priceData.market_price_change_dollars }}
                  </div>
                </td>
                <td>
                  <div class="name">溢/折价率</div>
                  <div class="price">
                    $ {{ priceData.premium_discount_percentage }}
                  </div>
                </td>
                <td>
                  <div class="name">30天价差中位值</div>
                  <div class="price">
                    $ {{ priceData.median_30_day_spread_percentage }}
                  </div>
                </td>
                <td>
                  <div class="name">净资产</div>
                  <div class="price">{{ priceData.net_assets }}</div>
                </td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="5">
        <b-card no-body class="prosharesPrice">
          <b-card-header>
            <b-card-title
              >Fund Holdings Information as of {{ datetime }}
            </b-card-title>
          </b-card-header>
          <b-card-body style="height: 357px">
            <el-table
              class="price"
              :data="tableData2"
              style="width: 100%; background-color: transparent"
              height="290"
              :row-class-name="tableRowClassName"
              :row-style="{ height: '48px' }"
            >
              <el-table-column prop="name" label="描述"> </el-table-column>
              <el-table-column prop="marketvalue" label="美金价值" width="140">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.marketvalue == null ? "-" : scope.row.marketvalue
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="shares" label="股票/合约" width="140">
              </el-table-column>
            </el-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="4" style="height">
        <b-card no-body class="prosharesPrice">
          <b-card-header> </b-card-header>
          <b-card-body style="height: 375px">
            <customized-pie ref="prosharesPrice" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>基金规模(USD) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="fundScale" />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>持仓规模(USD) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="PositionSize" />
          </b-card-body>
        </b-card>
        <!-- <analytics-support-tracker :data="data.supportTracker" /> -->
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { getBtfFundData, getBtfPriceData } from "@/api/BtcEtf.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import CustomizedPie from "@/views/charts-and-maps/charts/echart/option-echart/CustomizedPie.vue";
import Inventory from "@/views/charts-and-maps/charts/echart/option-echart/Inventory.vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    Inventory,
    CustomizedPie,
  },
  data() {
    return {
      tableData: [
        {
          name: "资产净值",
          price: "$40.91",
        },
        {
          name: "资产净值变化",
          price: "$-0.21",
        },
        {
          name: "市场收盘价格",
          price: "$40.89",
        },
        {
          name: "市场收盘价变化",
          price: "$-0.23",
        },
        {
          name: "日交易量",
          price: "5848809",
        },
        {
          name: "持仓",
          price: "21955 BTC",
        },
      ],
      tableData2: [],
      time: "", //价格信息时间
      datetime: "", //基金资产信息时间
      btfTotal: {
        bitcoin: 200,
        bill: 300,
        billFirst: 1,
        cashFirst: 2,
      },
      fundTimeData: [], //基金规模xAxis data时间间隔
      YcharData: [], //基金规模yAxis data数据
      positionTimeData: [], //持仓xAxis data时间间隔
      positionData: [], //持仓yAxis data数据
      tempData: [], //存放btf价格信息所有数据
      priceData: {}, //存放btf价格信息数据
    };
  },
  created() {
    this.getFundList();
    this.getPriceList();
  },
  mounted() {},
  methods: {
    //奇偶行背景色不同
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }

      return "evenRow";
    },
    //处理价格时间
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },

    //请求btf价格信息数据
    getPriceList() {
      getBtfPriceData()
        .then((res) => {
          let tempData = [...res.data.data];
          this.priceData = tempData[0];
          // console.log(this.priceData);
          //显示最近日期
          this.time = this.dateFtt(
            "yyyy-MM-dd",
            new Date(this.priceData.datetime)
          );
        })
        .catch((err) => {});
    },

    //处理圆饼数据用parseInt（）方法对两个数组数据进行相加保留整数
    getPaiData(bitDataNew, billDataNew, cashDataNew) {
      for (let i = 0; i < bitDataNew.length; i++) {
        this.btfTotal.bitcoin += parseInt(bitDataNew[i].marketvalue);
      }
      for (let i = 0; i < billDataNew.length; i++) {
        this.btfTotal.billFirst += parseInt(billDataNew[i].shares);
      }
      for (let i = 0; i < cashDataNew.length; i++) {
        this.btfTotal.cashFirst += parseInt(cashDataNew[i].shares);
      }
      this.btfTotal.bill = this.btfTotal.billFirst + this.btfTotal.cashFirst;
    },

    //获取基金规模时间间隔
    getundTime(bitData, billData) {
      let set = new Set();
      // 遍历美金日期
      for (let i = 0; i < bitData.length; i++) {
        let day =
          new Date(bitData[i].datetime).getDate() < 10
            ? "0" + new Date(bitData[i].datetime).getDate()
            : new Date(bitData[i].datetime).getDate();
        set.add(new Date(bitData[i].datetime).getMonth() + 1 + "-" + day);
      }
      this.positionTimeData = Array.from(set);
      // console.log("8524",this.positionTimeData)
      // 遍历基金日期
      for (let i = 0; i < billData.length; i++) {
        let day =
          new Date(billData[i].datetime).getDate() < 10
            ? "0" + new Date(billData[i].datetime).getDate()
            : new Date(billData[i].datetime).getDate();
        set.add(new Date(billData[i].datetime).getMonth() + 1 + "-" + day);
      }
      this.fundTimeData = Array.from(set);
      return this.fundTimeData;
    },

    //获取基金规模y轴数据
    getYcharData(date, bitData, billData) {
      //获取持仓规模y轴数据
      let positionDate = this.positionTimeData;
      for (let i = 0; i < positionDate.length; i++) {
        let positionount = 0;
        for (let b = 0; b < bitData.length; b++) {
          if (bitData[b].datetime.search(date[i]) != -1) {
            positionount += parseFloat(bitData[b].marketvalue / 100000000);
          }
        }
        //bitArr.push(bitCount.toFixed(2));
        //获取持仓规模y轴数据
        this.positionData.push(positionount.toFixed(2));
      }

      let bitArr = [];
      //for循环遍历第一个数组bitData里面是同一天的所有exposure_value值取整相加放进空数组里面
      for (let i = 0; i < positionDate.length; i++) {
        let bitCount = 0;
        for (let b = 0; b < bitData.length; b++) {
          if (bitData[b].datetime.search(date[i]) != -1) {
            bitCount += parseFloat(bitData[b].marketvalue / 100000000);
          }
        }
        bitArr.push(bitCount.toFixed(2));
        //获取持仓规模y轴数据
        //this.positionData.push(bitCount.toFixed(2));
      }

      let billArr = [];
      //for循环遍历第二个数组billData里面是同一天的所有exposure_value值取整相加放进空数组里面
      for (let i = 0; i < date.length; i++) {
        let bitCount = 0;
        for (let b = 0; b < billData.length; b++) {
          if (billData[b].datetime.search(date[i]) != -1) {
            bitCount += parseFloat(billData[b].shares / 100000000);
          }
        }
        billArr.push(bitCount.toFixed(2));
      }

      var bitAndBill = [];
      //for循环把两个数组同一天的值相加添加到新的数组尾末
      for (let i = 0; i < date.length; i++) {
        bitAndBill.push(
          (parseFloat(bitArr[i]) + parseFloat(billArr[i])).toFixed(2)
        );
      }
      return bitAndBill;
    },

    // 请求btf Fund Holdings Information基金控股数据
    getFundList() {
      getBtfFundData()
        .then((res) => {
          // 获取美金数据和基金数据
          let billData = res.data.data.TREASURY_BILL;
          let bitData = res.data.data.CME;
          let cashData = res.data.data.Cash;
          // 获取美金数据和基金数据的第一个日期值
          let latelyDate_bil = res.data.data.TREASURY_BILL[0].datetime;
          let latelyDate_bit = res.data.data.CME[0].datetime;
          let latelyDate_cash = res.data.data.Cash[0].datetime;
          // 过滤符合条件的数据
          let billDataNew = billData.filter(function (value, index, array) {
            return value.datetime == latelyDate_bil;
          });
          let bitDataNew = bitData.filter(function (value, index, array) {
            return value.datetime == latelyDate_bit;
          });
          let cashDataNew = cashData.filter(function (value, index, array) {
            return value.datetime == latelyDate_cash;
          });
          // console.log("cashDataNew", cashDataNew);

          // 获取美金价格和基金数据，组装为一个数组
          this.tableData2 = billDataNew.concat(bitDataNew).concat(cashDataNew);

          // Fund Holdings Information显示最近日期
          this.datetime = res.data.data.CME[0].datetime.replace("00:00:00", "");
          //处理圆饼数据用parseInt（）方法对两个数组数据进行相加保留整数
          this.getPaiData(bitDataNew, billDataNew, cashDataNew);

          //获取基金规模时间间隔
          this.getundTime(bitData, billData);

          // 遍历日期
          var bitArray = [];
          for (let i = 0; i < this.fundTimeData.length; i++) {
            // 遍历第一个数组中的值
            let bitCount = 0;
            for (let a = 0; a < bitData.length; a++) {
              // 根据日期
              if (bitData[a].datetime.search(this.fundTimeData[i]) != -1) {
                bitCount += parseInt(bitData[i].exposure_value);
                for (let b = 0; b < billData.length; b++) {
                  bitCount += parseInt(billData[b].shares_or_contracts);
                }
              }
            }
            bitArray.push(bitCount);
          }
          let date = this.getundTime(bitData, billData);
          //处理基金规模数据
          this.YcharData = this.getYcharData(date, bitData, billData);

          this.dealEchartData(1, this.btfTotal);
          this.fundScaleData(1, this.btfTotal2);
          this.fundScaleData(2, this.btfTotal2);
        })
        .catch((err) => {});
    },

    //获取圆饼两个data值
    dealEchartData(type, btfTotal) {
      if (type === 1) {
        this.$refs.prosharesPrice.chart.setOption({
          tooltip: {
            trigger: "item",
          },
          visualMap: {
            show: false,
            min: 80,
            max: 600,
            inRange: {
              colorLightness: [0, 1],
            },
          },
          series: [
            {
              name: "BTF持仓比例",
              type: "pie",
              radius: "55%",
              center: ["50%", "50%"],
              data: [
                {
                  value: btfTotal.bitcoin,
                  name: "CME期货持仓比例",
                  itemStyle: { color: "#EFC394" },
                },
                {
                  value: btfTotal.bill,
                  name: "现金等价物持仓比例",
                  itemStyle: { color: "#161D31" },
                },
              ].sort(function (a, b) {
                return a.value - b.value;
              }),
              roseType: "radius",
              label: {
                color: "#FFFF",
              },
              labelLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                },
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
              itemStyle: {
                color: "#c23531",
                shadowBlur: 200,
                shadowColor: "rgba(0, 0, 0, 1.0)",
                normal: {
                  //饼状图上的文本显示
                  label: {
                    show: true,
                    position: "outside", //标签的位置'outside'饼图扇区外侧'inside'饼图扇区内部。
                    formatter: "{d}%", //"{c}({d}%)"数值和百分比
                    textStyle: {
                      fontSize: 12,
                      fontWeight: "bolder",
                    },
                  },
                  labelLine: {
                    show: true, //显示饼状图上的文本时，指示线不显示，在第一个data时显示指示线
                  },
                },
              },
              animationType: "scale",
              animationEasing: "elasticOut",
              animationDelay: function (idx) {
                return Math.random() * 200;
              },
            },
          ],
        });
      }
    },
    //获取基金规模data值
    fundScaleData(type, btfTotal2) {
      if (type === 1) {
        this.$refs.fundScale.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          tooltip: {
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {
                var aaa = this.kmb(item.value);
                result += `<span style="">${item.seriesName} : ${aaa}亿</span></br>`;
              });
              return result;
            },
          },
          xAxis: {
            data: this.fundTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            nameTextStyle: {
              width: 100,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} 亿",
              textStyle: {
                color: "#FFF",
              },
            },
          },
          series: [
            {
              name: "资产净值",
              data: this.YcharData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#6FB8F7",
              },
            },
          ],
        });
      } else if (type === 2) {
        this.$refs.PositionSize.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          tooltip: {
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

              params.forEach((item) => {
                var aaa = this.kmb(item.value);
                result += `<span style="">${item.seriesName} : ${aaa}亿</span></br>`;
              });
              return result;
            },
          },
          xAxis: {
            data: this.positionTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} 亿",
              textStyle: {
                color: "#FFF",
              },
            },
          },
          series: [
            {
              name: "持仓",
              data: this.positionData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#EFC394",
              },
            },
          ],
        });
      }
    },
  },
};
</script>
<style lang="scss">
.proPrice {
  width: 100%;
  td {
    text-align: left;
    border-bottom: 1px solid rgba(247, 235, 235, 0.2);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    color: #ffff;
    .name {
      width: 70%;
    }
    .price {
      width: 30%;
    }
  }
}
.prosharesPrice {
  .card-header .card-title {
    font-size: 16px;
    font-weight: 600;
    color: #ffff;
  }
  .price {
    .el-table__header-wrapper {
      .has-gutter {
        tr {
          border-bottom: none !important;
          .el-table__cell {
            text-align: center;
            color: rgba(239, 195, 148, 1) !important;
            background-color: rgba(45, 47, 58, 1) !important;
            font-size: 14px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          }
        }
      }
    }
    th {
      // text-align: center !important;
      color: #ffff;
      background-color: #283046 !important;
      border-bottom: none !important;
    }
    tr {
      background: #33353e;
      border-bottom: none !important;
      .el-table__cell {
        // text-align: center !important;
        border-bottom: none !important;
      }
    }
  }
}
</style>
<style lang="scss">
.oddRow {
  //斑马纹样式

  color: white;

  background-color: rgba(102, 102, 102, 0.1) !important;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}

.evenRow {
  background-color: rgba(255, 255, 255, 0.1) !important;

  color: white;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}
</style>